.product-content {
    font-family: "Avenir-Light";
    font-size: 15px;
}

.breadcrumb-content .breadcrumb {
    background-color: transparent;
}

.product-image {
    margin-right: 10px;
    margin-bottom: 20px;
}

.product-image img {
    object-fit: cover;
    object-position: center;
    max-width: 100%;
    height: auto;
    max-height: 347px;
}

.product-details {
    margin-left: 10px;
}

.product-details h1 {
    font-family: "Avenir-Heavy";
    font-size: 25px;
    font-weight: 400;
    margin-bottom: .2em;
}

.request-product-form {
    margin-top: 20px;
}