.carousel-row {
    margin-top: 20px;
}

.carousel-caption h3 {
    position: relative;
    font-family: Avenir-Light;
    color: black;
    font-size: 28px;
    font-weight: 500;
}

.carousel-control {
    width: 5%;
    opacity: .25;
}

.carousel-control:hover, .carousel-control:focus {
    opacity: .5;
}

.section {
    margin-top: 60px;
    text-align: center;
}

.section h3 {
    font-family: "Avenir-Heavy";
    letter-spacing: 0.1em;
    color: black;
    font-size: 25px;
    font-weight: 800;
    text-transform: uppercase;
}

.products {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: nowrap;
}

.section .carousel-control-prev {
    left: -90px;
}

.section .carousel-control-next {
    right: -90px;
}

.section .carousel-control-prev,
.section .carousel-control-next {
    margin-bottom: 4.5em;
}

.section .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}

.section .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.mobile-products {
    display: none !important;
}

@media (max-width: 1000px) {
    .carousel-row {
        display: none !important;
    }
    
    .products {
      flex-direction: column;
      align-items: center;
    }

    .carousel-products {
        display: none !important;
    }

    .mobile-products {
        display: block !important;
    }
  }