.spacer {
    height: 20px;
}

.profile-text {
    font-family: "Avenir-Light";
    font-size: 17px;
    color: rgb(97, 96, 96);
    line-height: normal;
}

#profile-col {
    text-align: center;
}