.product-box {
    width: 300px;
    margin-left: 5px;
    margin-right: 5px;
}

.product-box,
.product-box-container,
.product-box-container-link {
    box-sizing: border-box;
    text-decoration: none;
}

.product-box-container-link {
    border-width: 0;
    border-color: #A6A6A6;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    border-style: solid;
    align-items: flex-start;
}

.visual-section-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.visual-section-wrapper img {
    object-fit: cover;
    object-position: center;
    width: 300px;
    height: 300px;
}

.content-elements {
    padding-top: 20px;
    padding-left: 23px;
    padding-right: 23px;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
}

.content-elements .product-name {
    color: #000000;
    display: block;
    margin-top: -1px;
    margin-bottom: -1px;
    line-height: 1.4!important;
    margin-top: 0;
    margin-bottom: 0;
}

.content-elements .product-box-separator {
    width: 20px;
    vertical-align: middle;
    border-bottom-color: #000000;
    border-top-width: 0;
    border-bottom-width: 2px;
    border-left-width: 0;
    border-right-width: 0;
    border-style: inset;
    margin-top: 15px;
    margin-bottom: 15px;
    display: inline-block;
}

.content-elements .price {
    color: #A6A6A6;
    font-family: "Avenir-Light",sans-serif;
    line-height: 1.4em;
    position: relative;
}

.content-elements .prices {
    line-height: 1.4!important;
    white-space: nowrap;
}
