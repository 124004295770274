.footer-col {
    font-family: "Avenir-Light";
    font-size: 14px;
    color: rgb(97, 96, 96);
    margin: 20px auto;
    width: 980px;
}

.footer-col a {
    color: rgb(97, 96, 96);
}

.footer-col .container-fluid {
    margin-bottom: 30px;
}

.footer-copyright {
    background-color: rgb(232, 230, 230);
    
}

.footer-text {
    text-align: center;
}
