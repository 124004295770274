h2.title {
    font-size: 15px;
    text-align: center;
    font-family: "Avenir-Heavy";
    font-weight: 400;
    letter-spacing: 0.2em;
    margin-top: 30px;
}

.divider {
    width: 20px;
    height: 5px;
    border-top: 2px solid black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.about-me-text {
    font-family: "Avenir-Light";
    font-size: 17px;
    color: rgb(97, 96, 96);
}

.form-group .btn-primary {
    color: #ffffff;
}

.form-group .help-block {
    display: none;
}